const messages = {
  date: 'Dátum',
  date_from: 'Od',
  date_to: 'Do',
  budget: 'Budget KPI´s',
  nmh_amb: 'NMH amb. KPI´s',
  pageViews: 'PV',
  realUsers: 'RU',
  videoViews: 'VV',
  realityVsKpi: 'realita vs KPI',
  new: {
    pageViews: {
      tooltipInfo: '<div style="width: 400px">*normalizované PV - prepočet PV podľa rozdielu<br /> monetizačného potenciálu medzi <br /> článkovým a galériovým PV, v pomere: 1 článkové PV = 14 galériových PV</div>',
      header: 'Pageviews (normalizované)',
      budget: 'Budget mesačných PV',
      pageViews: 'Priebežné reálne plnenie PV',
      realityVsKpi: 'Budget vs forecast z priebežneho plnenia PV'
    },
    realUsers: {
      header: 'Real Users',
      budget: 'Budget denných priemerných RU',
      uniqueUsersDailyAvg: 'Priebežný denný priemer RU',
      realityVsKpi: 'Budget vs priebežný denný priemer RU'
    },
    videoViews: {
      header: 'Videoviews',
      budget: 'Budget mesačných VV',
      videoViews: 'Priebežné reálne plnenie VV',
      realityVsKpi: 'Budget vs forecast z priebežného plnenia VV'
    },
    averageTimeSpent: {
      header: 'Average Time Spent',
      budget: 'Budget priemerného denného TS per RU',
      timeSpentPerUserDailyAvg: 'Priebežný denný priemer TS per RU',
      realityVsKpi: 'Budget vs priebežný priemerný denný TS per RU'
    }
  }
}

export default messages
