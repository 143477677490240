import PermissionService from '@/services/PermissionService'

const recipeRequiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.RECIPE_PERMISSIONS
const recipeCategoryRequiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_RECIPE_CATEGORY_PERMISSIONS

const routes = [
  {
    path: 'recipe/new',
    component: () => import('../../views/recipe/RecipeNewView'),
    name: 'recipe_new',
    meta: {
      description: 'Recipe new',
      requiresAuth: true,
      requiredPermissionModules: recipeRequiredPermissions.newRoute
    }
  },
  {
    path: 'recipe/:id/edit',
    component: () => import('../../views/recipe/RecipeEditView'),
    name: 'recipe_edit',
    meta: {
      description: 'Recipe edit',
      requiresAuth: true,
      requiredPermissionModules: recipeRequiredPermissions.editRoute
    }
  },
  {
    path: 'recipe/:id',
    component: () => import('../../views/recipe/RecipeView'),
    name: 'recipe_detail',
    meta: {
      description: 'Recipe detail',
      requiresAuth: true,
      requiredPermissionModules: recipeRequiredPermissions.detailRoute
    }
  },
  {
    path: 'recipe',
    component: () => import('../../views/recipe/RecipeListView'),
    name: 'recipe_list',
    meta: {
      description: 'Recipe list',
      requiresAuth: true,
      requiredPermissionModules: recipeRequiredPermissions.listRoute
    }
  },
  {
    path: 'recipeCategory/:id/edit',
    component: () => import('../../views/recipe/RecipeCategoryEditView'),
    name: 'recipe_category_edit',
    meta: {
      description: 'Recipe category edit',
      requiresAuth: true,
      requiredPermissionModules: recipeCategoryRequiredPermissions.editRoute
    }
  },
  {
    path: 'recipeCategory/new',
    component: () => import('../../views/recipe/RecipeCategoryNewView'),
    name: 'recipe_category_new',
    meta: {
      description: 'Recipe category new',
      requiresAuth: true,
      requiredPermissionModules: recipeCategoryRequiredPermissions.newRoute
    }
  },
  {
    path: 'recipeCategory',
    component: () => import('../../views/recipe/RecipeCategoryListView'),
    name: 'recipe_category_list',
    meta: {
      description: 'Recipe category list',
      requiresAuth: true,
      requiredPermissionModules: recipeCategoryRequiredPermissions.listRoute
    }
  }
]

export default routes
