import CoreApi from '@/api/core'
import { dataHubRecommend } from '@/api/datahub'
import { i18n } from '@/main'
import NotifyService from '@/services/NotifyService'

const aiFeaturesUsageApi = '/ai-features-usage-log'

const state = {
  relatedArticles: [],
  recommendedIds: [],
  lastBodyText: ''
}

const mutations = {
  SET_RELATED_ARTICLES (state, articles) {
    state.relatedArticles = articles
  },
  SET_RECOMMENDED_IDS (state, ids) {
    state.recommendedIds = ids
  },
  SET_BODY_TEXT (state, bodyText) {
    state.lastBodyText = bodyText
  }
}

const actions = {
  logAiFeaturesUsage ({ commit }, payload) {
    return CoreApi().post(aiFeaturesUsageApi, payload)
      .catch(error => {
        console.error('Error logging AI features usage:', error)
      })
  },
  async fetchRelatedArticles ({ commit }, bodyText) {
    commit('SET_RELATED_ARTICLES', [])
    if (bodyText && bodyText !== state.lastBodyText) {
      commit('SET_BODY_TEXT', bodyText)
      try {
        const result = await dataHubRecommend().post('', { body_text: bodyText }, { timeout: 180000 })
        const recommendedIds = result.data.documentIds
        commit('SET_RECOMMENDED_IDS', recommendedIds)
        if (recommendedIds.length > 0) {
          const url = `/article?view=minimal&filter_eq[setting.status]=published&filter_in[documentId]=${recommendedIds}`
          const response = await CoreApi().get(url)
          if (response.status === 200) {
            response.data.data.forEach(article => {
              article.aiRelated = true
            })
          }
          commit('SET_RELATED_ARTICLES', response.data.data)
        }
      } catch (error) {
        if (error.response.status === 400 && error.response.data.detail === 'Lenght of the article is too short') {
          NotifyService.setErrorMessage(i18n.t('article.ai_service.recommended_articles_error_title'), i18n.t('article.ai_service.recommended_articles_error_body'), 7000)
        } else if (error.response.status === 404 && error.response.data.detail === 'Not Found') {
          NotifyService.setErrorMessage(i18n.t('article.ai_service.recommended_articles_service_unavailable'))
        } else {
          console.error(error)
        }
      }
    }
  }
}

const getters = {
  relatedArticles: state => state.relatedArticles,
  lastBodyText: state => state.lastBodyText,
  recommendedIds: state => state.recommendedIds
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
