import _ from 'lodash'
import EdonApi from '../../api/edon'
import KpiSettingsFilter from '../../model/KpiSettingsFilter'
import Kpi from '../../model/Kpi'
import KpiUser from '../../model/KpiUser'
import KpiPerfornmance from '../../model/PerformanceKpi'

const API_NAME = '/kpi-settings'

const state = {
  error: null,
  loaded: false,
  filter: KpiSettingsFilter,
  kpi: _.cloneDeep(Kpi)
}

const mutations = {
  storeKpi (state, responseData) {
    state.kpi = responseData
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  setLoaded (state, loaded) {
    state.loaded = loaded
  },
  setError (state, message) {
    state.error = message
  },
  updateKpi (state, monthYear) {
    if (state.kpi.month !== parseInt(monthYear[0]) || state.kpi.year !== parseInt(monthYear[1])) {
      state.kpi.id = null
      state.kpi.month = monthYear[0]
      state.kpi.year = monthYear[1]
    }
  },
  syncKpiUsers (state) {
    const userKpis = []
    state.filter.departmentUsers.forEach((userId) => {
      let existingUserKpi = null
      let userKpi = _.cloneDeep(KpiUser)

      if (state.kpi.usersKpi !== null) {
        existingUserKpi = state.kpi.usersKpi.filter(u => u.userId === userId)
      }
      if (existingUserKpi !== null && existingUserKpi.length > 0) {
        userKpi = existingUserKpi[0]
      } else {
        userKpi.userId = userId
        userKpi.userKpi = _.cloneDeep(KpiPerfornmance)
      }
      userKpis.push(userKpi)
    })
    state.kpi.usersKpi = userKpis
  }
}

const actions = {
  async fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    if (store.state.filter.department) {
      const monthYear = store.state.filter.monthYear.split('-')
      return await EdonApi().get(API_NAME + '/' + store.state.filter.department +
        '/' + monthYear[0] + '/' + monthYear[1]
      )
        .then(response => {
          store.commit('storeKpi', response.data)
          store.commit('syncKpiUsers')
          store.commit('setLoaded', true)
          store.commit('updateKpi', monthYear)
          store.commit('TOGGLE_LOADING', null, { root: true })
        })
        .catch(error => {
          console.log(error)
          store.commit('TOGGLE_LOADING', null, { root: true })
        })
    }
  },
  async create (store) {
    return await EdonApi().post(API_NAME, JSON.stringify(store.state.kpi))
      .then(response => {
        if (response.status === 200) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  }
}

const getters = {
  kpi (state) {
    return state.kpi
  },
  suggestedPageViews (state) {
    return state.kpi.suggestedPageViews
  },
  suggestedVideoViews (state) {
    return state.kpi.suggestedVideoViews
  },
  filter (state) {
    return state.filter
  },
  error (state) {
    return state.error
  },
  loaded (state) {
    return state.loaded
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
