const messages = {
  list: {
    id: 'Id',
    title: 'Title',
    published_since: 'Published since',
    published_until: 'Published until',
    status: 'Status',
    layout: 'Layout',
    active: 'Active',
    inactive: 'Inactive',
    type: 'Type',
    category: 'Category',
    hidden: 'Hidden',
    created_at: 'Created at',
    created_by: 'Created by'
  },
  filter: {
    title: 'Title',
    id: 'Id',
    status: 'Status',
    layout: 'Layout',
    created_at_from: 'Created at from',
    active: 'Active',
    inactive: 'Inactive',
    category: 'Category',
    search: 'Search'
  },
  enabled: 'Enabled',
  main_tab: 'Main content',
  questions_results_tab: 'Questions and results of the quiz',
  one_question_tab: 'Question and answers',
  question_number: '{number}. Question',
  answer_number: 'Answer {number}',
  id: 'Id',
  title: 'Title',
  title_placeholder: 'Write title',
  published_since: 'Published since',
  published_until: 'Published until',
  description: 'Description',
  image: 'Image',
  type: 'Type',
  category: 'Category',
  layout: 'Layout',
  show_image: 'Show image',
  questions_per_page: 'Questions per page',
  rewriteArticle: 'Overwrite the data in the article with data from the quiz',
  categories: 'Categories',
  question_info_text: 'Pre pridanie otázky je potrebne kvíz najprv uložiť.',
  questions_info_text: 'Pre pridanie otázok je potrebne kvíz najprv uložiť.',
  settings: 'Settings',
  results_header: 'Hodnotenia kvízu',
  result_number: '{number}. Result',
  result: 'Hodnotenie',
  result_title: 'Title',
  result_score: 'Score',
  result_description: 'Description',
  result_image: 'Image',
  enter_minimum_two_answers: 'Please add at least 2 answers !',
  select_date: 'Select date',
  delete_result: 'Delete result',
  add_result: 'Add result',
  question_list: 'Question list',
  question_title: 'Question title',
  question_description: 'Question description',
  question_image: 'Question image',
  question_answers: 'Answers',
  question_answer: 'Answer',
  answer_title: 'Title',
  answer_description: 'Description',
  answer_points: 'Points',
  answer_image: 'Image',
  add_question: 'Add question',
  remove_question: 'Remove question',
  add_answer: 'Pridať odpoveď',
  remove_answer: 'Zmazať odpoveď',
  create_quiz_article: 'Create quiz article',
  create_quiz_article_info: 'First, fill in the "Site" field. Then confirm the image that will be the Main Image in the article. And then you will be able to create a new article.',
  quiz_article_created: 'New quiz article draft was created !',
  quiz_article_created_error: 'Error ! Problem with creating image for quiz article.',
  quiz_article_modal_header: 'Pre vytvorenie článku je potrebné kvíz aktivovať. Aktivovať teraz?',
  yes: 'Yes',
  no: 'No',
  save_and_create_new: 'Save and create a new question',
  max_possible_number_of_points: 'The maximum possible number of points',
  main_content: 'Main content',
  headline_used_in_articles: 'Used in article',
  quiz_article_not_found: 'Quiz article not found.',
  quiz_article_exists: 'Pre kvíz už existuje článok !',
  quiz_type: {
    personality: 'Personalized',
    correct_answer: 'True/False',
    one_question: 'One question with detail'
  },
  deprecated_quiz_type_info: 'After saving, it will not be possible to set "Personalized" again because this type is disabled.',
  deprecated_quiz_layout_info: 'After saving, it will not be possible to set "Image answers" again because this layout is disabled.',
  set_published_until: 'Set published until',
  set_order_date_to_now: 'Set now'
}

export default messages
