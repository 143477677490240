import coreApi from '../../api/core'
import ArticleModel from '../../model/ArticleModel.js'
import ArticleFilterModel from '../../model/ArticleFilter'
import ArticleFilterService from '../../services/article/ArticleFilterService'
import config from '../../config'
import ArticleService from '../../services/article/ArticleService'
import _ from 'lodash'
import NotifyService from '@/services/NotifyService'
import { i18n, router } from '@/main'
import { ENTITY_LOG_ARTICLE } from '@/model/ValueObject/EntityLogTypes'

const API_NAME = '/article'
const API_ELASTIC = '/article_elastic'

const callCreateArticle = async ({ store, partialApi, payload }) => {
  await coreApi().post(`${API_NAME}/${partialApi}`, payload)
    .then(response => {
      if (response.status === 201) {
        store.commit('storeDetail', response.data)
        store.commit('setError', null)
      } else {
        store.commit('setError', 'Error')
      }
    })
    .catch(error => {
      if (error.response.status === 500) {
        store.commit('setError', error.response.status)
      } else {
        store.commit('setError', error.response.data.error)
      }
    })
}

const state = {
  error: null,
  detail: ArticleModel,
  list: [],
  totalCount: 0,
  page: 1,
  filter: _.cloneDeep(ArticleFilterModel),
  lastFiltered: null,
  relatedArticles: [],
  embedsRelatedArticles: [],
  relatedDiseaseArticles: [],
  embedsRelatedDiseaseArticles: [],
  autoLogoutArticle: null,
  searchInEnabledSites: false,
  view: 'minimal',
  currentSiteName: '',
  currentSiteId: null,
  isElasticAllowed: config.articleSetting.elasticArticleListing,
  alternativeTitleCheckbox: false,
  articleEditDisabled: false
}

const mutations = {
  SET_ALTERNATIVE_TITLE_CHECKBOX (state, data) {
    state.alternativeTitleCheckbox = data
  },
  SET_CURRENT_SITE_ID (state, siteId) {
    state.currentSiteId = siteId
  },
  SET_CURRENT_SITE_NAME (state, data) {
    state.currentSiteName = data
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  clearList (state) {
    state.list = []
    state.totalCount = 0
  },
  addMediaToGallery (state, media) {
    state.detail.expanded.gallery.unshift(media)
  },
  removeMediaFromGallery (state, mediaToRemove) {
    state.detail.expanded.gallery = state.detail.expanded.gallery.filter(media => media.id !== mediaToRemove.id)
  },
  updateGallery (state, updatedGallery) {
    state.detail.expanded.gallery = updatedGallery
  },
  storeRelatedArticles (state, relatedArticles) {
    state.relatedArticles = relatedArticles
  },
  storeEmbedsRelatedArticles (state, relatedArticles) {
    state.embedsRelatedArticles = relatedArticles
  },
  addRelatedArticle (state, article) {
    let exist = false
    state.relatedArticles.forEach(relatedArticle => {
      if (article.documentId === relatedArticle.documentId) {
        exist = true
      }
    })
    if (exist === false) {
      state.relatedArticles.push(article)
    }
  },
  removeRelatedArticle (state, articleToRemove) {
    state.relatedArticles = state.relatedArticles.filter(article => article.id !== articleToRemove.id)
  },
  storeRelatedDiseaseArticles (state, relatedDiseaseArticles) {
    state.relatedDiseaseArticles = relatedDiseaseArticles
  },
  storeEmbedsRelatedDiseaseArticles (state, relatedDiseaseArticles) {
    state.embedsRelatedDiseaseArticles = relatedDiseaseArticles
  },
  addRelatedDiseaseArticle (state, article) {
    let exist = false
    state.relatedDiseaseArticles.forEach(relatedDiseaseArticle => {
      if (article.documentId === relatedDiseaseArticle.documentId) {
        exist = true
      }
    })
    if (exist === false) {
      state.relatedDiseaseArticles.push(article)
    }
  },
  removeRelatedDiseaseArticle (state, articleToRemove) {
    state.relatedDiseaseArticles = state.relatedDiseaseArticles.filter(article => article.id !== articleToRemove.id)
  },
  setError (state, errorObject) {
    state.error = errorObject
  },
  setFilter (state, filter) {
    const date = new Date()
    state.lastFiltered = date.getTime()
    state.filter = filter
  },
  resetFilter (state) {
    state.filter = _.cloneDeep(ArticleFilterModel)
    const date = new Date()
    state.lastFiltered = date.getTime()
    state.page = 1
  },
  setPage (state, page) {
    state.page = page
  },
  setAutoLogoutArticle (state, article) {
    state.autoLogoutArticle = article
  },
  setSearchInEnabledSites (state, status) {
    state.searchInEnabledSites = status
  },
  setView (state, view = 'minimal') {
    state.view = view
  },
  setArticleEditDisabled (state, articleEditDisabled) {
    state.articleEditDisabled = articleEditDisabled
  }
}

const actions = {
  fetch (store, data) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const hiddenOnHp = data === 'hiddenOnHp' ? '&filter_eq[flag.hiddenOnHp]=0' : ''
    const offset = (store.state.page * config.article.list.limit) - config.article.list.limit
    const api = state.isElasticAllowed() === 'true' && data !== 'withoutElastic' ? API_ELASTIC : API_NAME

    const url = api +
      '?view=' + store.state.view + '&limit=' + config.article.list.limit +
      '&offset=' + offset +
      hiddenOnHp +
      ArticleFilterService.buildFilterQuery(store.state.filter)
    return coreApi().get(url)
      .then(async response => {
        // this is fetching only rubrics that are not fetched yet - see 'rubric/fetchRubricsBySiteId' for details
        const rubricPromises = response.data.data.map(article => store.dispatch('rubric/fetchRubricsBySiteId', article.site, { root: true }))
        await Promise.all(rubricPromises)
        store.commit('storeList', response.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.error(error)
        NotifyService.setErrorMessage(i18n.t('article.articleListError'))
        store.commit('clearList')
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  fetchByDocumentId (store, data) {
    let api = state.isElasticAllowed() === 'true' ? API_ELASTIC : API_NAME
    if (
      typeof data === 'object' &&
      data !== null &&
      Object.prototype.hasOwnProperty.call(data, 'withoutElastic')
    ) {
      if (data.withoutElastic) {
        api = API_NAME
      }
    }
    const url = `${api}?view=full&filter_eq[documentId]=${data?.documentId}`
    return coreApi().get(url).then(response => {
      return Promise.resolve(response.data.data)
    })
  },
  async storeDetailAfterFetch (store, detail) {
    store.commit('storeRelatedArticles', [])
    store.commit('storeEmbedsRelatedArticles', [])
    store.commit('storeRelatedDiseaseArticles', [])
    store.commit('storeEmbedsRelatedDiseaseArticles', [])
    store.commit('storeDetail', detail)
  },
  async fetchOne (store, id) {
    await coreApi().get(API_NAME + '/' + id)
      .then(response => {
        store.dispatch('storeDetailAfterFetch', response.data)
      })
      .catch(error => {
        console.error(error)
        throw error
      })
  },
  async fetchArticleLog (store) {
    await coreApi().get(`/entityLog/${ENTITY_LOG_ARTICLE}/${state.detail.entityUuid}`)
      .then(async (response) => {
        if (response.status === 200) {
          store.dispatch('storeDetailAfterFetch', JSON.parse(response.data.data))
          await store.dispatch('processFetchedFullArticle')
          NotifyService.setSuccessMessage(i18n.t('article.modal.unsaved_version.loading_log_success'))
        } else {
          NotifyService.setErrorMessage(i18n.t('article.modal.unsaved_version.loading_log_error'))
        }
      })
      .catch(error => {
        console.error(error)
        NotifyService.setErrorMessage(i18n.t('article.modal.unsaved_version.loading_log_error'))
      })
  },
  async fetchFullArticle (store, id) {
    try {
      await store.dispatch('fetchOne', id)
      await store.dispatch('processFetchedFullArticle')
    } catch (error) {
      NotifyService.setErrorMessage(i18n.t('article.notify.draft_load_error'))
      router.push('/article')
    }
  },
  async processFetchedFullArticle (store) {
    if (state.detail.relatedArticles) {
      let articleRelatedIds = ''
      state.detail.relatedArticles.map(article => article.relatedArticleDocId).forEach(documentId => {
        articleRelatedIds += documentId + ','
      })
      if (articleRelatedIds) {
        await store.dispatch('fetchRelatedArticles', articleRelatedIds)
      }
    }

    if (state.detail.articleEmbeds) {
      let articleRelatedIds = ''
      let diseaseArticleRelatedIds = ''
      state.detail.articleEmbeds.forEach(embed => {
        if (embed.discriminator === 'relatedArticle') {
          articleRelatedIds += embed.relatedArticleDocId + ','
        } else if (embed.discriminator === 'relatedDiseaseArticle') {
          diseaseArticleRelatedIds += embed.relatedDiseaseArticleDocId + ','
        }
      })
      if (articleRelatedIds) {
        await store.dispatch('fetchEmbedsRelatedArticles', articleRelatedIds)
      }
      if (diseaseArticleRelatedIds) {
        await store.dispatch('fetchEmbedsRelatedDiseaseArticles', diseaseArticleRelatedIds)
      }
    }

    if (state.detail.relatedDiseaseArticles) {
      let diseaseArticleRelatedIds = ''
      state.detail.relatedDiseaseArticles.map(article => article.documentId).forEach(documentId => {
        diseaseArticleRelatedIds += documentId + ','
      })
      if (diseaseArticleRelatedIds) {
        await store.dispatch('fetchRelatedDiseaseArticles', diseaseArticleRelatedIds)
      }
    }
  },
  async create (store, article) {
    await callCreateArticle({ store, partialApi: 'create', payload: article })
  },
  async createClone (store, article) {
    await callCreateArticle({ store, partialApi: `create-clone?articleId=${article.id}`, payload: undefined })
  },
  async createByTakeover (store, article) {
    await callCreateArticle({ store, partialApi: 'create-by-takeover', payload: article })
  },
  async createFromTasr (store, article) {
    await callCreateArticle({ store, partialApi: 'create-from-tasr', payload: article })
  },
  async createFromQuiz (store, article) {
    await callCreateArticle({ store, partialApi: 'create-from-quiz', payload: article })
  },
  async update (store, article) {
    const url = `${API_NAME}/${article.id}`
    const payload = ArticleService.prepareApiRequest(article)
    return await coreApi().put(url, JSON.stringify(payload))
      .then(response => {
        if (response.status === 200) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        const errorObject = {
          status: error.response?.status,
          message: error.message,
          stack: error.stack,
          url: `PUT ${url}`,
          payload
        }
        store.commit('setError', errorObject)
      })
  },
  async deleteRecord (store, record) {
    return await coreApi().delete(API_NAME + '/' + record.id)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data)
        }
      })
  },
  fetchRelatedArticles (store, articleIds) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const api = state.isElasticAllowed() === 'true' ? API_ELASTIC : API_NAME
    const url = api + '?view=minimal&filter_eq[setting.status]=published&filter_in[documentId]=' + articleIds
    return coreApi().get(url)
      .then(response => {
        store.commit('storeRelatedArticles', response.data.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  fetchArticlesForRelatedList (store, data) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const api = state.isElasticAllowed() === 'true' ? API_ELASTIC : API_NAME
    const hiddenOnHp = data.hiddenOnHp === 'hiddenOnHp' ? '&filter_eq[flag.hiddenOnHp]=0' : ''
    const offset = (store.state.page * config.article.list.limit) - config.article.list.limit
    let limit = config.article.list.limit
    if (store.state.page === 1) {
      limit = data.firstPagelimit
    }
    const url = `${api}?view=${store.state.view}&limit=${limit}&offset=${offset}${hiddenOnHp}${ArticleFilterService.buildFilterQuery(store.state.filter)}`

    return coreApi().get(url)
      .then(async response => {
        store.commit('storeList', response.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.error(error)
        NotifyService.setErrorMessage(i18n.t('article.articleListError'))
        store.commit('clearList')
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchEmbedsRelatedArticles (store, articleIds) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const api = state.isElasticAllowed() === 'true' ? API_ELASTIC : API_NAME
    const url = api + '?view=minimal&filter_eq[setting.status]=published&filter_in[documentId]=' + articleIds
    await coreApi().get(url)
      .then(response => {
        store.commit('storeEmbedsRelatedArticles', response.data.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  fetchRelatedDiseaseArticles (store, articleIds) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const api = state.isElasticAllowed() === 'true' ? API_ELASTIC : API_NAME
    const url = api + '?view=minimal&filter_eq[setting.status]=published&filter_in[documentId]=' + articleIds
    return coreApi().get(url)
      .then(response => {
        store.commit('storeRelatedDiseaseArticles', response.data.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchEmbedsRelatedDiseaseArticles (store, articleIds) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const api = state.isElasticAllowed() === 'true' ? API_ELASTIC : API_NAME
    const url = api + '?view=minimal&filter_eq[setting.status]=published&filter_in[documentId]=' + articleIds
    await coreApi().get(url)
      .then(response => {
        store.commit('storeEmbedsRelatedDiseaseArticles', response.data.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchTransferredArticles (store, articleId) {
    const url = `${API_NAME}/${articleId}/transferred-articles`
    return await coreApi().get(url)
      .then(response => {
        const articles = response.data
        if (articles) {
          return Promise.resolve(articles)
        }
        return []
      })
      .catch(error => {
        console.error(error)
      })
  },
  async updateFbShared (store, { article }) {
    return await coreApi().patch(`${API_NAME}/${article.documentId}/fbShared/${article.setting.fbShared ? 1 : 0}`)
      .then(response => {
        if (response.status === 200) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data)
        }
      })
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  /* Do not remove state, getters and rootState argument, it's neccesary for rootGetters, magic */
  fullArticle (state, getters, rootState, rootGetters) {
    const fullArticle = state.detail
    const preparedRubrics = []
    fullArticle.expanded.rubrics.forEach(rubric => {
      if (rubric) {
        const site = rootGetters['site/siteById'](rubric.site)
        let siteTitle = site.title
        if (site.shortTitle !== '') {
          siteTitle = site.shortTitle
        }
        const preparedRubric = {
          id: rubric.id,
          title: siteTitle + ' / ' + rubric.title
        }
        if (rubric.parent > 0) {
          const parentRubric = rootGetters['rubric/rubricById'](rubric.parent)
          if (parentRubric) {
            preparedRubric.title = siteTitle + ' / ' + (parentRubric?.title ?? rubric.parent) + ' - ' + rubric.title
          }
        }
        preparedRubrics.push(preparedRubric)
      }
    })
    fullArticle.rubrics = preparedRubrics
    return fullArticle
  },
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  },
  lastFiltered (state) {
    return state.lastFiltered
  },
  page (state) {
    return state.page
  },
  relatedArticles (state) {
    return state.relatedArticles
  },
  embedsRelatedArticles (state) {
    return state.embedsRelatedArticles
  },
  relatedDiseaseArticles (state) {
    return state.relatedDiseaseArticles
  },
  embedsRelatedDiseaseArticles (state) {
    return state.embedsRelatedDiseaseArticles
  },
  autoLogoutArticle (state) {
    return state.autoLogoutArticle
  },
  articleEditDisabled (state) {
    return state.articleEditDisabled
  },
  searchInEnabledSites (state) {
    return state.searchInEnabledSites
  },
  currentSiteId (state) {
    return state.currentSiteId
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
