import PermissionService from '@/services/PermissionService'
import { PERMISSION_VIEW } from '@/model/ValueObject/UserPermissions'
import { MODULE_ARTICLE } from '@/model/ValueObject/UserPermissionModules'
import coreApi from '@/api/core'
import ssoApi from '@/api/sso'
import appConfig from '@/config'
import SsoLoginService from '@/services/user/SsoLoginService'

const CORE_API_NAME = '/user'

window.DEBUG = {
  ...(window.DEBUG ?? {}),
  userStore: false
}

const getExpiresAt = () => {
  const expiresAt = localStorage.getItem('expires_at')
  return expiresAt ? +expiresAt : null
}

const state = {
  token: null,
  currentUser: {
    username: '',
    email: 'anonymous@gmail.com',
    sites: [],
    permissionModules: null
  },
  refreshTimeout: null
}

const mutations = {
  authUser (state, token) {
    state.token = token
    if (window.DEBUG.userStore) {
      console.debug(`authUser, ${state.token ? 'token exists' : 'token is ' + state.token}`)
    }
  },
  storeCurrentUser (state, currentUser) {
    state.currentUser = currentUser
  },
  clearAuthData (state) {
    state.token = null
    if (window.DEBUG.userStore) {
      console.debug(`clearAuthData, ${state.token ? 'token exists' : 'token is ' + state.token}`)
    }
  },
  storeAll (state, responseData) {
    state.all = responseData.data
  },
  saveRefreshTimeout (state, refreshTimeout) {
    state.refreshTimeout = refreshTimeout
  },
  clearRefreshTimeout (state) {
    if (state.refreshTimeout) {
      clearTimeout(state.refreshTimeout)
      state.refreshTimeout = null
    }
  }
}

const actions = {
  async fetchAll ({ commit }) {
    const url = `${CORE_API_NAME}?limit=${2000}&view=minimal`
    await coreApi().get(url)
      .then(res => {
        commit('storeAll', res.data)
      })
      .catch(error => console.error(error))
  },
  async setRefreshTimer ({ commit, dispatch }) {
    const now = Date.now()
    const expiresAt = getExpiresAt()
    const expirationTime = expiresAt - now
    if (!expiresAt || expirationTime < 60000) {
      const ssoLogoutUrl = await dispatch('logout')
      window.open(ssoLogoutUrl, '_self')
      return false
    }
    // refresh 30 seconds before expiration to always have a valid token and to prevent auto-logout on invalid token
    const refreshTime = expirationTime - 30000
    if (window.DEBUG.userStore) {
      const refreshMinutes = Math.round(refreshTime / 1000 / 60)
      const refreshHours = Math.round(refreshMinutes / 60)
      console.debug(`setRefreshTimer: expiresAt: ${new Date(expiresAt).toLocaleString()}, refreshTime: ${refreshMinutes} minutes (cca ${refreshHours} hours)`)
    }
    /**
     * Max 10 days to auto refresh. More than 24 days is bigger than 32-bit int and timeout would execute immediately with an infinite loop.
     */
    const maxRefreshTime = 10 * 24 * 60 * 60 * 1000
    if (refreshTime > maxRefreshTime) {
      if (window.DEBUG.userStore) {
        console.debug('setRefreshTimer: timer not set, the refreshTime is too big')
      }
      return true // refresh time is valid, but setting timeout is not possible and not needed
    }

    commit('clearRefreshTimeout')
    const refreshTimeoutId = setTimeout(async () => {
      await dispatch('refreshToken')
      // this sets the refresh timer again but with a new 'expiresAt' (in case there is a different value from SSO API)
      await dispatch('setRefreshTimer')
    }, refreshTime)
    commit('saveRefreshTimeout', refreshTimeoutId)
    return true
  },
  async saveUserTokensToLocalStorage (context, user) {
    localStorage.setItem('access_token', 'Bearer ' + user.access_token)
    localStorage.setItem('refresh_token', user.refresh_token)
    localStorage.setItem('expires_at', user.expires_at * 1000)
    localStorage.setItem('id_token', user.id_token)
  },
  async login ({ dispatch }, user) {
    await dispatch('saveUserTokensToLocalStorage', user)
    await dispatch('tryAutoLogin') // this will work as we've set the 'access_token' above
  },
  async tryAutoLogin ({ commit, dispatch, getters }) {
    if (window.DEBUG.userStore) {
      console.debug('tryAutoLogin: START')
    }
    const now = Date.now()
    const expiresAt = getExpiresAt()
    if (!expiresAt || now > expiresAt) {
      // authentication expired, remove invalid token
      if (window.DEBUG.userStore) {
        console.debug(`   tryAutoLogin: authentication expired, removing invalid token, now: ${new Date(now).toLocaleString()}, expiresAt: ${new Date(expiresAt).toLocaleString()}, token: ${localStorage.getItem('access_token')}`)
      }
      localStorage.removeItem('access_token')
      commit('clearAuthData')
      return
    }

    const storedValidToken = localStorage.getItem('access_token')
    if (!storedValidToken) {
      if (window.DEBUG.userStore) {
        console.debug('   tryAutoLogin: no token in localStorage')
      }
      // cannot auto-login without token
      return
    }
    if (getters.isLoggedIn) {
      // already logged in
      // usually a redirect (user-action in the app)
      if (window.DEBUG.userStore) {
        console.debug('   tryAutoLogin: already logged in - usually a redirect (user-action in the app)')
      }
      return
    }
    if (window.DEBUG.userStore) {
      console.debug('   tryAutoLogin: user not logged in, continue with auto login')
    }

    commit('authUser', storedValidToken)
    const isRefreshTimerValid = await dispatch('setRefreshTimer')
    if (!isRefreshTimerValid) {
      return
    }

    dispatch('safetyTopic/fetchAll', null, { root: true })
    dispatch('category/fetchAll', null, { root: true })
    dispatch('dam/fetchConfigInfo', null, { root: true })
    dispatch('media/fetchImageSettings', null, { root: true })
    await dispatch('beUser/fetchAll', null, { root: true })
    await dispatch('user/fetchAll', null, { root: true })
    await dispatch('site/fetchAll', null, { root: true })
    await dispatch('department/fetchAll', null, { root: true })
    await dispatch('fetchAndStoreCurrentUser') // this needs to be done after dispatch('site/fetchAll') and dispatch('user/fetchAll')
  },
  async refreshToken ({ dispatch, commit }) {
    if (window.DEBUG.userStore) {
      console.debug('refreshToken: start')
    }
    const manager = SsoLoginService.getOidcUserManager()
    let refreshedUser
    await manager.signinSilent().then(user => {
      // !! do NOT call any ASYNC/AWAIT operations here, otherwise the silent callback is not closed properly
      refreshedUser = user
      manager.signinSilentCallback() // should close the callback
    }).catch(error => {
      console.error(error)
    })

    if (refreshedUser) {
      if (window.DEBUG.userStore) {
        console.debug('refreshToken: success')
      }
      await dispatch('saveUserTokensToLocalStorage', refreshedUser)
      const storedValidToken = localStorage.getItem('access_token')
      if (storedValidToken) {
        commit('authUser', storedValidToken)
      }
    } else {
      console.error('Refresh of a token failed.')
    }
  },
  async ssoLogout () {
    const client = SsoLoginService.getOidcClient()
    const idToken = localStorage.getItem('id_token')
    let ssoLogoutUrl = null
    try {
      const signOutResponse = await client.createSignoutRequest({ id_token_hint: idToken })
      ssoLogoutUrl = signOutResponse.url
    } catch (error) {
      console.error(error)
    }
    return ssoLogoutUrl
  },
  async logout ({ commit, dispatch }) {
    const ssoLogoutUrl = await dispatch('ssoLogout')
    commit('clearAuthData')
    commit('clearRefreshTimeout')
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('expires_at')
    localStorage.removeItem('id_token')
    return ssoLogoutUrl
  },
  async fetchAndStoreCurrentUser ({ commit, state, getters, rootGetters }) {
    if (!state.token) {
      return
    }

    const permissionModules = (await ssoApi().get('/myCapabilities')).data
    await ssoApi(appConfig.sso.apiTimeout, appConfig.sso.baseUrl()).get('/connect/userinfo')
      .then(res => {
        const profile = res.data
        const remoteUserId = profile.sub
        const currentUser = {
          remoteUserId,
          name: profile.name,
          firstName: profile.given_name,
          lastName: profile.family_name,
          username: profile.email,
          email: profile.email,
          permissionModules
        }
        commit('storeCurrentUser', currentUser)

        try {
          currentUser.id = getters.userByRemoteUserId(remoteUserId)?.id
          currentUser.sites = rootGetters['site/enabledSites'](MODULE_ARTICLE, PERMISSION_VIEW, permissionModules).map(site => site.id)
          commit('storeCurrentUser', currentUser)
        } catch (error) {
          // in case the user has no permissions,
          //   core-cms will not provide any more info
          //   and Admin will redirect to welcome page
          console.error(error)
        }
      })
      .catch(error => console.error(error))
  }
}

const getters = {
  currentUser (state) {
    return state.currentUser
  },
  isLoggedIn (state) {
    return state.token !== null
  },
  hasPermission (state) {
    return (requiredPermissions, siteName = null) => {
      return PermissionService.checkRequiredPermission(
        requiredPermissions,
        state.currentUser.permissionModules,
        siteName
      )
    }
  },
  hasPermissionForSite (state, getters, rootState, rootGetters) {
    return (requiredPermissions, siteId) => {
      if (!siteId) {
        throw Error('"siteId" cannot be null, you might want to use "hasPermission" getter')
      }
      const siteName = rootGetters['site/siteById'](siteId)?.name
      return PermissionService.checkRequiredPermission(
        requiredPermissions,
        state.currentUser.permissionModules,
        siteName
      )
    }
  },
  userById (state) {
    return id => state.all.find(user => user.id === id)
  },
  userByRemoteUserId (state) {
    return remoteUserId => state.all.find(user => user.remoteUserId === remoteUserId)
  },
  usersInDepartment (state) {
    return departmentId => state.all.filter(user => {
      if (parseInt(departmentId) === parseInt(user.department)) {
        return user
      }
    })
  },
  all (state) {
    return state.all
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
