import Configuration from './config/configuration'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { sync } from 'vuex-router-sync'
import * as Sentry from '@sentry/vue'
import routes from '@/router'
import store from '@/store'
import Notifications from 'vue-notification'
import Vuelidate from 'vuelidate'
import VueI18n from 'vue-i18n'
import lodash from 'lodash'
import VueLodash from 'vue-lodash'
import VuePapaParse from 'vue-papa-parse'
import ErrorHandlingService from '@/services/ErrorHandlingService'
import PermissionService from '@/services/PermissionService'
import UUID, { uuid } from 'vue-uuid'
import VueMeta from 'vue-meta'
import Sortable, { MultiDrag } from 'sortablejs'

// Import Helpers for filters
import {
  domain, count, toString, prettyDateTime, prettyDate, prettyTime, prettyJson,
  pluralize, truncate, formatNumber, formatPercentage, formatCurrency,
  formatSeconds, formatMilliseconds, capitalize
} from '@/filters'

// Import directives
import clickOutside from './plugins/directives/clickOutside'

// Import Views - Top level
import AppView from '@/App.vue'

// import Translation messages
import messages from '@/i18n/messages'
import { DEFAULT_ROUTE_NAME, DEFAULT_ROUTE_ALTERNATIVE_NAME } from '@/router/components/dashboardRoutes'

window.DEBUG = {
  ...(window.DEBUG ?? {}),
  mainJs: false
}

let i18n
let router

const createNewInstanceHash = () => {
  const newInstanceHash = uuid.v4()
  window.sessionStorage.setItem('instance_hash', newInstanceHash)
  return newInstanceHash
}
const getInstanceHash = () => {
  let instanceHash = window.sessionStorage.getItem('instance_hash')
  if (!instanceHash) {
    // in case the session got cleared by the browser and the 'instance_hash' is null, we need to create a new one
    instanceHash = createNewInstanceHash()
  }
  return instanceHash
}

createNewInstanceHash()

// Load config.json, then start app
fetch('/config.json').then(res => res.json())
  .then(config => {
    Configuration.setConfig(config)
    store.commit('SET_CONFIG', config)

    Vue.config.errorHandler = ErrorHandlingService.vueErrorHandler

    // Import Install and register helper items
    Vue.filter('count', count)
    Vue.filter('toString', toString)
    Vue.filter('domain', domain)
    Vue.filter('prettyDateTime', prettyDateTime)
    Vue.filter('prettyDate', prettyDate)
    Vue.filter('prettyTime', prettyTime)
    Vue.filter('prettyJson', prettyJson)
    Vue.filter('pluralize', pluralize)
    Vue.filter('truncate', truncate)
    Vue.filter('capitalize', capitalize)
    Vue.filter('formatNumber', formatNumber)
    Vue.filter('formatPercentage', formatPercentage)
    Vue.filter('formatCurrency', formatCurrency)
    Vue.filter('formatSeconds', formatSeconds)
    Vue.filter('formatMilliseconds', formatMilliseconds)

    Vue.use(VueRouter)
    Vue.use(Notifications)
    Vue.use(Vuelidate)
    Vue.use(VueI18n)
    Vue.use(VueLodash, { lodash: lodash })
    Vue.use(VuePapaParse)
    Vue.use(UUID)
    Vue.use(clickOutside)
    Vue.use(VueMeta)
    Sortable.mount(new MultiDrag())

    // Routing logic
    router = new VueRouter({
      routes: routes,
      mode: 'history',
      linkExactActiveClass: 'active',
      scrollBehavior: function (to, from, savedPosition) {
        return savedPosition || { x: 0, y: 0 }
      }
    })

    if (store.getters.production && !store.getters.vlm) {
      Sentry.init({
        Vue,
        dsn: 'https://f14ecd4a9aac4efdafdd74fdef427618@o4505237714239488.ingest.sentry.io/4505352034516992',
        integrations: [
          new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', /^https:\/\/core-eagle\.nmhonline\.sk\/api/, /^https:\/\/dam\.nmhmedia\.sk\/api/,
              /^https:\/\/core-eagle-staging\.nmhonline\.sk\/api/, /^https:\/\/damadmin-staging\.nmhmedia\.sk\/api/],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router)
          }),
          new Sentry.Replay({
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: true, // This needs to stay blocked, otherwise DAM in Admin cannot load images.
            mutationLimit: 1000, // default is 10000
            mutationBreadcrumbLimit: 500 // default is 750
          })
        ],
        // Performance Monitoring
        tracesSampleRate: 0.2, // Reduced to 20% in production!

        // Session Replay
        /**
         * The sample rate for replays that begin recording immediately and last the entirety of the user's session.
         * This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
         */
        replaysSessionSampleRate: 0.1,
        /**
         *  Sample rate for replays that are recorded when an error happens. This type of replay will record up to a minute of events prior to the error and continue recording until the session ends.
         *    'replaysOnErrorSampleRate' is evaluated and if it's sampled, the integration will begin buffering the replay and will only upload it to Sentry if an error occurs.
         * If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
         */
        replaysOnErrorSampleRate: 1.0
      })
    }

    const getClarityScript = (projectId) => {
      const clarityScript = document.createElement('script')
      clarityScript.type = 'text/javascript'
      clarityScript.innerHTML = `
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${projectId}");
      `
      return clarityScript
    }

    if (store.getters.vlm) {
      document.head.appendChild(getClarityScript('mcitxxgfq5'))
    } else {
      document.head.appendChild(getClarityScript('m8traqcnie'))
    }

    const hasRequiredPermission = (to) => {
      const userPermissionModules = router.app.$store.state.user.currentUser.permissionModules
      return PermissionService.hasRouteRequiredPermission(to, userPermissionModules)
    }

    // Some middleware to help us ensure the user is authenticated.
    router.beforeEach((to, from, next) => {
      if (window.DEBUG.mainJs) {
        console.debug(`router.beforeEach START: from '${from.path}' ---> to '${to.path}'`)
      }
      router.app.$store.dispatch('user/tryAutoLogin')
        .then(() => {
          // don't display login page if user is logged
          if (to.name === 'login' && router.app.$store.getters['user/isLoggedIn']) {
            if (window.DEBUG.mainJs) {
              console.debug('   router.beforeEach: tried to go to "login",  already logged in so just go to "\'"')
            }
            next({ path: '/' })
          }
          const accessToken = localStorage.getItem('access_token')
          if (
            to.matched.some(record => record.meta.requiresAuth) &&
            (!accessToken || accessToken === 'null')
          ) {
            // this route requires auth, check if logged in
            // if not, redirect to login page.
            window.console.log('Not authenticated')
            if (to.fullPath !== '/' && to.fullPath !== '/userLogout') {
              localStorage.setItem('redirect_path', to.fullPath)
            }
            if (window.DEBUG.mainJs) {
              console.debug('   router.beforeEach: Not authenticated. redirecting to "login"')
            }
            next({
              path: '/login',
              query: { redirect: to.fullPath }
            })
          } else {
            if (!hasRequiredPermission(to)) {
              // this prevents a loop of redirecting to 'login' and '/'
              // it means the login is successful but the user has no permission to the site
              if (to.name === DEFAULT_ROUTE_NAME) {
                if (window.DEBUG.mainJs) {
                  console.debug(`   router.beforeEach: login is successful but the user has no permission for "${DEFAULT_ROUTE_NAME}", trying to redirect to "${DEFAULT_ROUTE_ALTERNATIVE_NAME}"`)
                }
                next({ name: DEFAULT_ROUTE_ALTERNATIVE_NAME })
              } else if (to.path.includes('/edit')) {
                if (window.DEBUG.mainJs) {
                  console.debug('   router.beforeEach: login is successful but the user has no permission for "/edit" route, redirecting to view route without "/edit"')
                }
                // try 'view' if user has no permission for 'edit'
                next({ path: to.path.replace('/edit', '') })
              } else {
                if (window.DEBUG.mainJs) {
                  console.debug('   router.beforeEach: login is successful but the user has no permission, redirecting to "welcome"')
                }
                next({
                  name: 'welcome',
                  query: { redirect: to.fullPath }
                })
              }
            } else {
              if (window.DEBUG.mainJs) {
                console.debug('   router.beforeEach: all good, next()')
              }
              next()
            }
          }
        })
    })

    sync(store, router)

    // Check local storage to handle refreshes
    if (window.localStorage) {
      const contentBlockFilter = window.localStorage.getItem('contentBlock/hpManagementFilter')
      if (contentBlockFilter) {
        store.commit('contentBlock/setHpManagementFilter', JSON.parse(contentBlockFilter))
      }
    }

    // Create VueI18n instance with options
    let language = window.localStorage.getItem('lang')
    if (!language) {
      language = 'sk'
    }
    i18n = new VueI18n({
      locale: language, // set locale
      messages
    })

    // Set HTML lang
    document.documentElement.lang = language

    // eslint-disable-next-line no-new
    new Vue({
      router,
      store,
      i18n,
      render: h => h(AppView)
    }).$mount('#root')
  })
  .catch(error => {
    console.error('config.json is missing in /public folder (Please read README.md)')
    console.error(error)
  })

// change this. demo
// window.bugsnagClient = window.bugsnag('02fe1c2caaf5874c50b6ee19534f5932')
// window.bugsnagClient.use(window.bugsnag__vue(Vue))

export { i18n, getInstanceHash, router }
