import _ from 'lodash'
import EdonApi from '../../api/edon'
import ProductPerformanceFilter from '../../model/ProductPerformanceFilter'
import EditorialPerformanceWeights from '../../model/EditorialPerformanceWeights'
import UserPerformance from '../../model/UserPerformance'
import UserMetrics from '../../model/UserMetrics'
import OnlineArticles from '../../model/OnlineArticles'
import PrintArticles from '../../model/PrintArticles'
import Videos from '../../model/Videos'
import Kpi from '../../model/Kpi'
import NotifyService from '@/services/NotifyService'

const API_NAME = '/performance'
const API_NAME_GCP = '/gcp-performance'

const state = {
  loadedProduct: false,
  loadedDepartment: false,
  filter: ProductPerformanceFilter,
  weights: _.cloneDeep(EditorialPerformanceWeights),
  usersPerformance: [_.cloneDeep(UserPerformance)],
  usersMetrics: [_.cloneDeep(UserMetrics)],
  onlineArticles: [_.cloneDeep(OnlineArticles)],
  printArticles: [_.cloneDeep(PrintArticles)],
  videos: [_.cloneDeep(Videos)],
  kpi: _.cloneDeep(Kpi),
  usersPerformanceCount: 0,
  performanceStatistics: {}
}

const mutations = {
  storeWeights (state, responseData) {
    state.weights = responseData.weights
    if (responseData.weights.length > 0) {
      state.usersPerformance = responseData.weights[0].usersPerformance
      state.usersPerformanceCount = responseData.weights[0].usersPerformanceCount
    } else {
      state.usersPerformance = _.cloneDeep(UserPerformance)
      state.usersPerformanceCount = 0
    }
  },
  storeUsersMetrics (state, responseData) {
    state.usersMetrics = responseData.userMetrics
  },
  storeOnlineArticles (state, responseData) {
    state.onlineArticles = responseData.onlineArticles
  },
  storePrintArticles (state, responseData) {
    state.printArticles = responseData.printArticles
  },
  storeVideos (state, responseData) {
    state.videos = responseData.videos
  },
  storeKpi (state, responseData) {
    state.kpi = responseData.kpi
  },
  storeStatistics (state, responseData) {
    state.performanceStatistics = responseData.performanceStatistics
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  setLoadedProduct (state, loaded) {
    state.loadedProduct = loaded
  },
  setLoadedDepartment (state, loaded) {
    state.loadedDepartment = loaded
  },
  setUsersPerformance (state, usersPerformance) {
    state.usersPerformance = usersPerformance
  },
  setUsersPerformanceCount (state, usersPerformanceCount) {
    state.usersPerformanceCount = usersPerformanceCount
  }
}

const actions = {
  async fetchProductView (store) {
    store.commit('TOGGLE_LOADING', true, { root: true })
    const { product } = store.state.filter
    if (product) {
      let apiName = API_NAME
      if (store.state.filter.gcpData) {
        apiName = API_NAME_GCP
      }
      let timeout
      let url
      if (product === 'all') {
        timeout = 300
        url = `${apiName}/all-products`
      } else {
        timeout = 60
        url = `${apiName}/product/${store.state.filter.product}`
      }
      url = `${url}/${store.state.filter.timeGroupType}/${store.state.filter.seedDate}`
      return EdonApi(timeout).get(url)
        .then(response => {
          store.commit('storeWeights', response.data)
          store.commit('storeUsersMetrics', response.data)
          store.commit('storeOnlineArticles', response.data)
          store.commit('storePrintArticles', response.data)
          store.commit('storeStatistics', response.data)
          store.commit('setLoadedProduct', true)
        })
        .catch(error => {
          console.error(error)
          NotifyService.setApiErrorMessage(error)
        })
        .finally(() => { store.commit('TOGGLE_LOADING', false, { root: true }) })
    }
  },
  async fetchDepartmentView (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    if (store.state.filter.department) {
      let apiName = API_NAME
      if (store.state.filter.gcpData) {
        apiName = API_NAME_GCP
      }
      return await EdonApi().get(apiName + '/department/' + store.state.filter.department +
        '/' + store.state.filter.timeGroupType + '/' + store.state.filter.seedDate
      )
        .then(response => {
          store.commit('storeWeights', response.data)
          store.commit('storeUsersMetrics', response.data)
          store.commit('storeOnlineArticles', response.data)
          store.commit('storePrintArticles', response.data)
          store.commit('storeVideos', response.data)
          store.commit('storeStatistics', response.data)
          store.commit('storeKpi', response.data)
          store.commit('setLoadedDepartment', true)
          store.commit('TOGGLE_LOADING', null, { root: true })
        })
        .catch(error => {
          console.error(error)
          NotifyService.setApiErrorMessage(error)
          store.commit('TOGGLE_LOADING', null, { root: true })
        })
    }
  }
}

const getters = {
  weights (state) {
    return state.weights
  },
  usersMetrics (state) {
    return state.usersMetrics
  },
  usersMetricsCount (state) {
    return state.usersMetrics.length
  },
  usersPerformance (state) {
    return Array.isArray(state.usersPerformance) ? state.usersPerformance : []
  },
  usersPerformanceCount (state) {
    return state.usersPerformanceCount
  },
  onlineArticles (state) {
    return state.onlineArticles
  },
  onlineArticlesCount (state) {
    return state.onlineArticles.length
  },
  printArticles (state) {
    return state.printArticles
  },
  printArticlesCount (state) {
    return state.printArticles.length
  },
  videos (state) {
    return state.videos
  },
  videosCount (state) {
    return state.videos.length
  },
  filter (state) {
    return state.filter
  },
  loadedProduct (state) {
    return state.loadedProduct
  },
  loadedDepartment (state) {
    return state.loadedDepartment
  },
  performanceStatistics (state) {
    return state.performanceStatistics
  },
  kpi (state) {
    return state.kpi
  },
  usersKpi (state) {
    return state.kpi.usersKpi
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
