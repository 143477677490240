export default {
  article: 0,
  external: 0,
  video: 0,
  quiz: 0,
  recipe: 0,
  photostory: 0,
  test: 0,
  longform: 0,
  disease: 0,
  encyclopedy: 0
}
