import UserLoginAttemptList from '../../components/tracking/UserLoginAttemptList'
import PermissionService from '@/services/PermissionService'

const routes = [
  {
    path: 'userLoginAttempt',
    component: UserLoginAttemptList,
    name: 'userLoginAttempt_list',
    meta: {
      description: 'User Login Attempt list',
      requiresAuth: true,
      requiredPermissionModules: PermissionService
        .REQUIRED_PERMISSIONS
        .SYSTEM_USER_LOGIN_ATTEMPT_PERMISSIONS
        .listRoute
    }
  }
]

export default routes
