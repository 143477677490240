import { cloneDeep } from 'lodash'
import Kpi from './Kpi'

export default {
  target: cloneDeep(Kpi),
  current: cloneDeep(Kpi),
  completion: cloneDeep(Kpi),
  median: cloneDeep(Kpi),
  medianCompletion: cloneDeep(Kpi)
}
