import _ from 'lodash'
import EdonApi from '../../api/edon'
import WeightsSettingFilter from '../../model/WeightsSettingsFilter'
import PerformanceWeights from '../../model/PerformanceWeights'

const API_NAME = '/weights'

const state = {
  error: null,
  loaded: false,
  filter: WeightsSettingFilter,
  weights: [_.cloneDeep(PerformanceWeights)]
}

const mutations = {
  storeWeights (state, responseData) {
    state.weights = responseData
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  setLoaded (state, loaded) {
    state.loaded = loaded
  },
  addNewWeight (state, newWeight) {
    state.weights.push(newWeight)
  },
  setError (state, message) {
    state.error = message
  },
  updateWeights (state, monthYear) {
    state.weights.forEach((weight) => {
      if (weight.month !== parseInt(monthYear[0]) || weight.year !== parseInt(monthYear[1])) {
        weight.id = ''
        weight.month = monthYear[0]
        weight.year = monthYear[1]
      }
    })
  }
}

const actions = {
  async fetch (store) {
    if (store.state.filter.product || store.state.filter.department) {
      const monthYear = store.state.filter.monthYear.split('-')
      let polymorphId = store.state.filter.product
      if (store.state.filter.department > 0) {
        polymorphId = store.state.filter.department
      }
      return await EdonApi().get(API_NAME + '/' + polymorphId +
        '/' + monthYear[0] + '/' + monthYear[1] + '/' + 1
      )
        .then(response => {
          store.commit('storeWeights', response.data)
          store.commit('setLoaded', true)
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  add (store) {
    if (store.state.filter.product || store.state.filter.department) {
      let polymorphId = store.state.filter.product
      if (store.state.filter.department > 0) {
        polymorphId = store.state.filter.department
      }
      const monthYear = store.state.filter.monthYear.split('-')
      const newWeight = _.cloneDeep(PerformanceWeights)
      newWeight.productId = store.state.filter.product
      newWeight.departmentId = store.state.filter.department
      newWeight.polymorphId = polymorphId
      newWeight.month = monthYear[0]
      newWeight.year = monthYear[1]
      if (state.weights.length === 0) {
        newWeight.default = true
      }
      store.commit('addNewWeight', newWeight)
      store.commit('updateWeights', monthYear)
    }
  },
  toggleDefault (store, index) {
    if (state.weights[index].default) {
      state.weights[index].default = null
    } else {
      state.weights[index].default = true
    }
  },
  async save (store) {
    if (store.state.filter.product || store.state.filter.department) {
      let polymorphId = store.state.filter.product
      if (store.state.filter.department > 0) {
        polymorphId = store.state.filter.department
      }
      const monthYear = store.state.filter.monthYear.split('-')
      return await EdonApi().post(API_NAME + '/' + polymorphId +
        '/' + monthYear[0] + '/' + monthYear[1] + '/' + 1,
      { weights: state.weights }
      )
        .then(response => {
          if (response.status === 200) {
            store.commit('setError', null)
          } else {
            store.commit('setError', 'Error')
          }
        })
        .catch(error => {
          if (error.response.status === 500) {
            store.commit('setError', error.response.status)
          } else {
            store.commit('setError', error.response.data.error)
          }
        })
    }
  }
}

const getters = {
  weights (state) {
    return state.weights
  },
  filter (state) {
    return state.filter
  },
  error (state) {
    return state.error
  },
  loaded (state) {
    return state.loaded
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
