import DateTimeService from '../services/DateTimeService'

export default {
  video: 0,
  imageGallery: 0,
  exclusive: 0,
  commentary: 0,
  interview: 0,
  online: 0,
  poll: 0,
  updated: 0,
  updatedUntilDatetime: DateTimeService.dateTimeMax,
  justNow: 0,
  justNowUntilDatetime: DateTimeService.dateTimeMin,
  native: 0,
  photostory: 0,
  quiz: 0,
  likeDislike: 0,
  hiddenOnHp: false // only VLM
}
