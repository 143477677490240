const messages = {
  date: 'Datum',
  date_from: 'Od',
  date_to: 'Do',
  budget: 'Budget KPI´s',
  nmh_amb: 'NMH amb. KPI´s',
  pageViews: 'PV',
  realUsers: 'RU',
  videoViews: 'VV',
  realityVsKpi: 'realita vs KPI',
  new: {
    pageViews: {
      tooltipInfo: '<div style="width: 400px">*normalizované PV - přepočet PV podle rozdílu<br /> monetizačního potenciálu mezi <br /> článkovým a galeriovým PV, v poměru: 1 článkové PV = 14 galeriových PV</div >',
      header: 'Pageviews (normalizované)',
      budget: 'Budget měsíčních PV',
      pageViews: 'Průběžné reálné plnění PV',
      realityVsKpi: 'Budget vs forecast z průběžného plnění PV'
    },
    realUsers: {
      header: 'Real Users',
      budget: 'Budget denních průměrných RU',
      uniqueUsersDailyAvg: 'Průběžný denní průměr RU',
      realityVsKpi: 'Budget vs průběžný denní průměr RU'
    },
    videoViews: {
      header: 'Videoviews',
      budget: 'Budget měsíčních VV',
      videoViews: 'Průběžné reálné plnění VV',
      realityVsKpi: 'Budget vs forecast z průběžného plnění VV'
    },
    averageTimeSpent: {
      header: 'Average Time Spent',
      budget: 'Budget průměrného denního TS per RU',
      timeSpentPerUserDailyAvg: 'Průběžný denní průměr TS per RU',
      realityVsKpi: 'Budget vs průběžný průměrný denní TS per RU'
    }
  }
}

export default messages
