import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_FEATURE_PERMISSIONS

const routes = [
  {
    path: 'feature/:id/edit',
    component: () => import('../../views/feature/FeatureEditView'),
    name: 'feature_edit',
    meta: {
      description: 'Feature edit',
      requiresAuth: true
    },
    requiredPermissionModules: requiredPermissions.editRoute
  },
  {
    path: 'feature',
    component: () => import('../../views/feature/FeatureListView'),
    name: 'feature_list',
    meta: {
      description: 'Feature list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
