<template>
  <div>

    <Checkbox
      v-if="someEditableBoolean"
      id="showOnlyTickedCheckboxes"
      v-model="showOnlyTickedCheckboxes"
      :label="$t('buttons.show_only_selected')"
    />
    <TooltipInfoText
      v-if="this.tooltipText.show"
      class="tooltip-info-text-component"
      :position="tooltipText.position"
      :title="tooltipText.text"
    />
    <div id="scrollableDiv" class="table-responsive" :style="tableHeight ? `height: ${tableHeight}` : '' ">
      <table id="list-table" class="table table-striped">
        <thead>
        <TableSupHeader :config="config">
          <template v-slot:first-column-slot>
            <slot name="first-column-slot"></slot>
          </template>
        </TableSupHeader>
        <tr>
          <th v-if="selectable" width="10"></th>
          <th v-if="showSelectButton" width="20"></th>
          <th @click="sortData(field)" v-for="(fieldLabel, field, index) in config.fields" :key="`item-${index}`"
              :class="{[`table-field-${field}`]: field}"
              v-show="!showOnlyTickedCheckboxes || isSomeRecordTrue(field)">
            <slot :name="`header-${field}`">
              <div class="d-flex">
                <Checkbox
                  v-if="isEditableBoolean(field)"
                  :id="`item-${index}-${field}-all`"
                  :value="getSumValueForAllRecords(field)"
                  :disabled="disabled"
                  @mousedown="keepScrollLeft"
                  @input="(checked) => booleanFieldCheckedAll(checked, field)"
                  label=""
                />
                <div class="tableHeader">
                  <span class="tableHeader-fieldLabel" style="font-size: medium">{{ getFieldLabel(fieldLabel) }}</span>
                  <div class="icons-wrapper">
                    <i
                      v-if="config.toolTip && config.toolTip.hasOwnProperty(field)"
                      class="info-icon fas fa-info-circle"
                      @mouseover="showTooltip($event, config.toolTip[field], true)"
                      @mouseleave="showTooltip($event, '', false)">
                    </i>
                    <i v-if="dataSortedBy === field && dataSortedOrder === 1" class="fa fa-angle-double-up"></i>
                    <i v-if="dataSortedBy === field && dataSortedOrder === -1" class="fa fa-angle-double-down"></i>
                  </div>
                </div>
              </div>
            </slot>
          </th>
          <th v-if="config.actions" width="120">
            <TooltipSlot
              v-if="config.toolTip && config.toolTip.hasOwnProperty('actions')"
              :title="config.toolTip['actions']"
              position="absolute"
            >{{ $t('actions') }}
            </TooltipSlot>
            <span v-else>{{ $t('actions') }}</span>
          </th>
        </tr>
        </thead>
        <tbody v-if="dataView.length > 0">
        <tr
          v-for="(record, index) in dataView"
          :key="`item-${index}-${record.id}-${record.key}`"
          :class="getRowClass(record)"
          v-show="showRow(record)"
        >
          <td v-if="selectable">
            <input
              type="checkbox"
              :id="'article_' + record.id"
              class="filled-in"
              :value="record"
              v-model="selectedDataRecords"
              @click="selectRecord(record)"
              :disabled="isCheckboxDisabled(record.id)"
            >
            <label :for="'article_' + record.id"></label>
          </td>
          <td v-if="showSelectButton">
            <button
              class="btn btn-default btn-sm"
              :title="$t('buttons.select')"
              @click="selectRecord(record)"
            >
              {{ $t('buttons.select') }}
            </button>
          </td>
          <td
            v-for="(fieldLabel, field, columnIndex) in config.fields"
            :key="`item-${field}`"
            :class="{ 'd-flex': showExpandedIcon(record, columnIndex) }"
            @click="expandToggle(record)"
            v-show="!showOnlyTickedCheckboxes || isSomeRecordTrue(field)"
          >
            <ArrowDownIcon
              v-if="showExpandedIcon(record, columnIndex)"
              class="arrow-icon"
            />
            <Checkbox
              v-if="showEditableCheckbox(field, record)"
              :id="`item-${index}-${field}-${record.id}`"
              :value="getCheckboxValue(record, field)"
              :disabled="disabled || getCheckboxDisabled(record, field)"
              :tooltip="getCheckboxTooltip(record, field)"
              :class="{ 'checkboxWithTooltip': disabledCheckboxTooltip && getCheckboxDisabled(record, field) }"
              @input="(checked) => booleanFieldChecked(checked, record, field)"
              @mousedown="keepScrollLeft"
              label=""
            />
            <IconCheck v-else-if="showCheckmark(field, record)" class="check-icon"/>
            <span v-else v-html="render(field, record)"/>
          </td>
          <td v-if="config.actions">
            <template v-for="(actionValue, actionName) in config.actions">
              <router-link
                v-if="actionName === 'detail'"
                :key="record.id + actionName"
                tag="a"
                :target="actionsHrefTarget"
                class="btn btn-default btn-sm"
                :to="{ name: config.actions.detail, params: { id: getValue(record, actionIdParam) }}"
                data-test="article_list_btn_detail"
              >
                <i class="fa fa-info"></i>
              </router-link>
              <BtnCopyToClipboard
                :key="record.id + actionName"
                data-test="article_list_btn_copy"
                v-if="actionName === 'copyToClipboard'"
                :data="copyToClipboardValue(record, config.actions.copyToClipboard)"
                :notify-message="$t('notify.id_was_copied')"
              >
              </BtnCopyToClipboard>
              <router-link
                v-if="actionName === 'edit' && hasEditPermission"
                :key="record.id + actionName"
                tag="a"
                :target="actionsHrefTarget"
                class="btn btn-default btn-sm"
                :to="{ name: config.actions.edit, params: { id: getValue(record, actionIdParam) }}"
                data-test="article_list_btn_edit"
              >
                <i class="fa fa-edit"></i>
              </router-link>
              <template
                v-if="actionName === 'custom'"
              >
                <template v-for="customButton in config.actions.custom">
                  <router-link
                    v-if="record[customButton.route_source_param]"
                    :key="record.id + actionName + customButton.title"
                    tag="a"
                    :target="actionsHrefTarget"
                    class="btn btn-default btn-sm"
                    :title="(customButton.title) ? customButton.title : ''"
                    :to="{name: customButton.route_name,
                                      params: customButtonActionParams(record, customButton)}"
                  >
                    <i :class="['fa', customButton.icon]"></i>
                    <span v-if="customButton.text">
                      {{ customButton.text }}
                    </span>
                  </router-link>
                </template>
              </template>
              <template
                v-if="actionName === 'event' && showEventButtons"
              >
                <button
                  v-for="eventButton in config.actions.event"
                  :key="record.id + actionName + eventButton.title"
                  type="button"
                  class="btn btn-default btn-sm"
                  @click="emitEvent(eventButton.event, record)"
                  :title="(eventButton.title) ? eventButton.title : ''"
                >
                  <i :class="['fa', eventButton.icon]"></i> <span v-if="eventButton.text">
                                        {{ eventButton.text }}
                                    </span>
                </button>
              </template>
            </template>
          </td>
        </tr>
        </tbody>
      </table>
      <div v-if="!callingAPI && data.length === 0">
        <p class="alert alert-info">
          {{ $t('result_not_found') }}
        </p>
      </div>
    </div>
    <div class="row" v-if="pagination">
      <div class="col-lg-6">
        <p class="m-t-20">{{ $t('found') }}: {{ totalCount }}</p>
      </div>
      <div class="col-lg-6">
        <div id="editable-datatable_wrapper" class="dataTables_wrapper" v-if="data.length > 0">
          <div class="dataTables_paginate paging_simple_numbers" id="editable-datatable_paginate">
            <Paginate
              v-model="currentPage"
              :page-count="pageCount"
              :page-range="5"
              :margin-pages="2"
              :click-handler="setPageAndGetRecords"
              :prev-text="$t('page_prev')"
              :next-text="$t('page_next')"
              :container-class="'pagination'"
              :prev-class="'paginate_button previous'"
              :next-class="'paginate_button next'"
              :page-class="'paginate_button'">
            </Paginate>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import TooltipInfoText from '../tooltip/TooltipInfoText'
import TableSupHeader from '../dataTable/TableSupHeader.vue'
import Config from '../../config'
import BtnCopyToClipboard from '../buttons/BtnCopyToClipboard'
import UserService from '../../services/user/UserService'
import TooltipSlot from '../tooltip/TooltipSlot'
import Checkbox from '@/components/form/Checkbox'
import IconCheck from '@/assets/img/svg/check.svg?inline'
import ArrowDownIcon from '@/assets/img/svg/arrow-down.svg?inline'
import {
  FIELD_EXPANDED,
  FIELD_PARENT,
  FIELD_TYPE_EDITABLE_BOOLEAN
} from '@/model/ValueObject/DataTableFields'
import { mapState } from 'vuex'

const DATETIME_FIELDS = [
  'createdAt',
  'modifiedAt',
  'publishedSince',
  'publishedUntil',
  'startOfVoting',
  'endOfVoting',
  'orderDate',
  'article.orderDate',
  'lastLogin',
  'changedAt',
  'contentCreated',
  'publicationDate',
  'cancelledAt'
]

const DATE_FIELDS = [
  'nextPaymentAt',
  'activeSince',
  'activeUntil',
  'expirationAt'
]

export default {
  name: 'DataTable',
  props: {
    data: {
      type: Array
    },
    totalCount: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1
    },
    config: {
      type: Object,
      default: () => {}
    },
    selectable: {
      type: Boolean,
      default: false
    },
    expandable: {
      type: Boolean,
      default: false
    },
    showSelectButton: {
      type: Boolean,
      default: false
    },
    disableMultipleSelect: {
      type: Boolean,
      default: false
    },
    maxSelected: {
      type: Number,
      default: null
    },
    selectedRecords: {
      type: Array,
      default: () => []
    },
    actionsInBlankWindow: {
      type: Boolean,
      default: false
    },
    actionIdParam: {
      type: String,
      default: 'id'
    },
    pagination: {
      type: Boolean,
      default: true
    },
    limit: {
      type: Number,
      default: Config.defaults.list.limit
    },
    toggleSelectGroup: {
      type: Object,
      default: () => {}
    },
    dataSortedBy: {
      type: String,
      default: null
    },
    dataSortedOrder: {
      type: Number,
      default: null
    },
    hasEditPermission: {
      type: Boolean,
      default: true
    },
    showEventButtons: {
      type: Boolean,
      default: true
    },
    tableHeight: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showOnlySelectedCheckboxes: {
      type: Boolean,
      default: false
    },
    disabledCheckboxTooltip: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      currentPage: 1,
      tooltipText: {
        show: false,
        text: '',
        position: {
          x: 0,
          y: 0
        }
      },
      selectedDataRecords: [],
      scrollableDivLeft: 0,
      showOnlyTickedCheckboxes: this.showOnlySelectedCheckboxes
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    dataView () {
      if (this.someEditableBoolean && this.showOnlyTickedCheckboxes) {
        // filters out all children rows without any ticked checkbox
        const data = this.data.filter(item => {
          let trueCheckbox = false
          for (const fieldName of Object.keys(this.config.fields)) {
            if (this.showEditableCheckbox(fieldName, item) && this.getCheckboxValue(item, fieldName)) {
              trueCheckbox = true
            }
          }
          return !item[FIELD_PARENT] || trueCheckbox
        })
        // filters out all parent rows without children rows
        return data.filter((item, index) => {
          return item[FIELD_PARENT] || (index < data.length - 1 && data[index + 1][FIELD_PARENT])
        })
      }
      return this.data
    },
    someEditableBoolean () {
      if (!this.config.fieldTypes) {
        return false
      }
      return Object.keys(this.config.fieldTypes).some(key => this.config.fieldTypes[key] === FIELD_TYPE_EDITABLE_BOOLEAN)
    },
    pageCount () {
      return Math.ceil(this.totalCount / this.limit)
    },
    actionsHrefTarget () {
      let target = '_self'
      if (this.actionsInBlankWindow) {
        target = '_blank'
      }
      return target
    }
  },
  components: {
    Paginate,
    BtnCopyToClipboard,
    TooltipSlot,
    Checkbox,
    IconCheck,
    ArrowDownIcon,
    TooltipInfoText,
    TableSupHeader
  },
  methods: {
    showRow (item) {
      if (this.expandable) {
        return !item[FIELD_PARENT] || item[FIELD_EXPANDED]
      }
      return true
    },
    isEditableBoolean (fieldName) {
      return this.config.fieldTypes?.[fieldName] === FIELD_TYPE_EDITABLE_BOOLEAN
    },
    showEditableCheckbox (fieldName, item) {
      return this.isEditableBoolean(fieldName) && typeof this.getCheckboxValue(item, fieldName) === 'boolean'
    },
    keepScrollLeft () {
      this.scrollableDivLeft = document.getElementById('scrollableDiv').scrollLeft
    },
    setScrollLeft () {
      document.getElementById('scrollableDiv').scrollLeft = this.scrollableDivLeft
    },
    booleanFieldChecked (checked, item, fieldName) {
      this.setScrollLeft()
      this.$emit('boolean-field-checked', { item: item, fieldName, checked })
    },
    booleanFieldCheckedAll (checked, fieldName) {
      this.setScrollLeft()
      this.$emit('boolean-field-checked-all', { fieldName, checked })
    },
    expandToggle (item) {
      if (this.isItemExpandable(item)) {
        this.$emit('expand-toggle', item)
      }
    },
    showCheckmark (field, record) {
      return this.render(field, record) === 'showCheckmark'
    },
    isItemExpandable (item) {
      return this.expandable && !item[FIELD_PARENT]
    },
    getRowClass (item) {
      const isExpandable = this.isItemExpandable(item)
      return {
        'highlight-row': this.highlightRow(item),
        'expandable-row': isExpandable,
        expanded: isExpandable && item[FIELD_EXPANDED],
        'expandable-table': this.expandable
      }
    },
    showExpandedIcon (item, columnIndex) {
      return this.isItemExpandable(item) && columnIndex === 0
    },
    sortData (sortBy) {
      this.$emit('data-sort-by', sortBy)
    },
    getValue (item, fieldName) {
      const fieldNameParts = fieldName.split('.')
      let value = item
      for (let i = 0; i < fieldNameParts.length; i++) {
        value = value[fieldNameParts[i]]
        if (!value) {
          return value
        }
      }

      return value
    },
    getCheckboxValue (item, fieldName) {
      const value = this.getValue(item, fieldName)
      if (typeof value?.value === 'boolean') {
        return value.value
      }
      return value
    },
    getCheckboxDisabled (item, fieldName) {
      const value = this.getValue(item, fieldName)
      if (typeof value?.disabled === 'boolean') {
        return value.disabled
      }
      return false
    },
    getCheckboxTooltip (item, fieldName) {
      if (this.getCheckboxDisabled(item, fieldName)) {
        const value = this.getValue(item, fieldName)
        return value.checkboxTooltip ?? this.disabledCheckboxTooltip ?? undefined
      }
      return undefined
    },
    getSumValueForAllRecords (fieldName) {
      let noBooleanItem = true
      if (this.isEditableBoolean(fieldName)) {
        if (this.data?.length === 0) {
          return false
        }
        for (const item of this.data) {
          const value = this.getCheckboxValue(item, fieldName)
          if (value === false) {
            return false
          }
          if (typeof value === 'boolean') {
            noBooleanItem = false
          }
        }
        if (noBooleanItem) {
          return false
        }
        // returns true only if all the values are true or undefined
        //   undefined value is used in case of groups/expanded
        return true
      }

      // implementation is only for a checkbox for now
      return null
    },
    isSomeRecordTrue (fieldName) {
      if (this.isEditableBoolean(fieldName)) {
        if (this.data?.length === 0) {
          return true
        }
        for (const item of this.data) {
          const value = this.getCheckboxValue(item, fieldName)
          if (value === true) {
            return true
          }
        }
        // returns false only if all the values are false or undefined
        //   undefined value is used in case of groups/expanded
        return false
      }

      // implementation is only for a checkbox for now
      return true
    },
    userNameById (id) {
      const user = this.$store.getters['user/userById'](id)
      if (user) {
        return UserService.getUserInfo(user)
      }
      return id
    },
    setPageAndGetRecords () {
      this.$emit('page-change', this.currentPage)
    },
    getFieldLabel (fieldLabel) {
      if (fieldLabel instanceof Object) {
        Object.keys(fieldLabel).forEach(key => {
          fieldLabel = fieldLabel[key]
        })
      }

      return fieldLabel
    },
    render (fieldName, item) {
      const value = this.getValue(item, fieldName)

      if (DATETIME_FIELDS.indexOf(fieldName) >= 0) {
        if (value) {
          return this.$options.filters.prettyDateTime(value)
        }
        return ''
      }
      if (DATE_FIELDS.indexOf(fieldName) >= 0) {
        if (value) {
          return this.$options.filters.prettyDate(value)
        }
        return ''
      }
      if (this.config.render && Object.prototype.hasOwnProperty.call(this.config.render, fieldName)) {
        return this.config.render[fieldName](value, item)
      }
      if (fieldName === 'createdBy' || fieldName === 'modifiedBy') {
        return this.userNameById(value)
      }
      if ((fieldName === 'feUserExpanded' || fieldName === 'feUserFull') && value && value.email) {
        return value.email
      }
      if (fieldName === 'field.title' && item.aiRelated) {
        return `<div class="ai-badge label text-uppercase">${this.$t('article.ai_service.status_ai_recommended')}</div>` + ' ' + value
      }
      return value
    },
    isCheckboxDisabled (id) {
      if (this.maxSelected !== null) {
        const selected = this.selectedDataRecords.find(item => item.id === id)
        if (selected || this.selectedDataRecords.length < this.maxSelected) {
          return false
        }
        return true
      }
      return false
    },
    selectRecord (record) {
      let removed = false
      this.selectedDataRecords.forEach((selectedRecord, index) => {
        if (selectedRecord.id === record.id) {
          this.selectedDataRecords.splice(index, 1)
          removed = true
        }
      })

      if (removed === false) {
        if (this.disableMultipleSelect) {
          this.selectedDataRecords = [record]
        } else {
          this.selectedDataRecords.push(record)
        }
      }
      this.$emit('select-record', this.selectedDataRecords)
    },
    selectGroup ({ ids, markAll }) {
      if (markAll) {
        ids.forEach(id => {
          const record = this.data.find(record => record.id === id)
          if (!this.selectedDataRecords.includes(record)) {
            this.selectedDataRecords.push(record)
          }
        })
      } else {
        this.selectedDataRecords = this.selectedDataRecords.filter(record => !ids.includes(record.id))
      }
      this.$emit('select-record', this.selectedDataRecords)
    },
    copyToClipboardValue (record, field) {
      return record[field].toString()
    },
    customButtonActionParams (record, customButton) {
      if (!record[customButton.route_source_param]) {
        return null
      }
      return { [customButton.route_param]: record[customButton.route_source_param].toString() }
    },
    highlightRow (record) {
      if (this.config.highlightRow) {
        return this.config.highlightRow(record)
      }

      return false
    },
    showTooltip (event, text, show = false) {
      console.log('test')
      const iconRect = event.target.getBoundingClientRect()
      const tableResponsive = event.target.closest('.table-responsive').getBoundingClientRect()
      if (show) {
        const offsetX = 0
        const offsetY = 105
        this.tooltipText.position.x = iconRect.left - tableResponsive.left + offsetX
        this.tooltipText.position.y = tableResponsive.bottom - iconRect.top + offsetY
        this.tooltipText.text = text
      }
      this.tooltipText.show = show
    },
    emitEvent (name, value) {
      this.$emit(name, value)
    },
    setupTooltipEvents () {
      this.$nextTick(() => {
        const elements = this.$el.querySelectorAll('.ai-badge')
        elements.forEach(el => {
          el.addEventListener('mouseover', (event) => {
            const tooltipText = this.$t('article.ai_service.ai_modal.modal_related_modal_tooltip')
            this.showTooltip(event, tooltipText, true)
          })
          el.addEventListener('mouseleave', (event) => {
            this.showTooltip(event, '', false)
          })
        })
      })
    }
  },
  watch: {
    selectedRecords (newRecords, oldRecords) {
      if (oldRecords.length !== newRecords.length) {
        this.selectedDataRecords = newRecords
      }
    },
    toggleSelectGroup: {
      deep: true,
      handler (newVal) {
        this.selectGroup(newVal)
      }
    }
  },
  updated () {
    this.currentPage = this.page
    this.setupTooltipEvents()
  },
  created () {
    this.currentPage = this.page
    this.selectedDataRecords = this.selectedRecords
  }
}
</script>

<!-- Scoped CSS -->
<style lang="scss" scoped>

.table-responsive {
  position: relative;
  th, td {
    font-size: 14px;
  }

  thead th {
    position: sticky;
    top: 0px;
    background-color: #FFFFFF;
    z-index: 10;
  }

  .table {
    // this prevents painting of the table beyond the screen
    // and helps to keep the vertical scroll in place after rerendering
    contain: paint;
  }

  th {
    .tableHeader {
      display: flex;
      flex-direction: row ;
      justify-content: flex-start;
      align-items: center;

      .tableHeader-fieldLabel {
        font-weight: bold;
        font-size: medium;
      }
      .icons-wrapper {
        display: flex;
        i {
          display: inline-block;
          margin-left: 0.5rem;
          color: #1e88e5;
          font-size: 1.2rem;
        }
        .info-icon {
          color:rgb(132, 144, 167);
          cursor: pointer;
        }
      }
    }
  }
}

.table-field-id {
  width: 40px;
}

.table-field-createdAt {
  width: 140px;
}

.highlight-row {
  background-color: #ffe52b61 !important;
}

.expandable-table:not(.expandable-row) {
  td:first-child {
    background-color: white;
    border-top: 0px;
  }
}
.expandable-row {
  cursor: pointer;
  font-weight: 700;
}
.arrow-icon {
  @include size(11px);
  fill: #abbdcc;
  transition: all 300ms;
  transform: rotate(-90deg);
  pointer-events: none;
  margin: 3px 6px 0px 0px;
  .expanded & {
    transform: rotate(0deg);
  }
}
</style>

<!-- Global CSS -->
<style lang="scss">
.article-type-icon {
  font-size: 18px;
  color: #181c22;
}

td span.code {
  font: 11px Courier, sans-serif;
}

.ai-badge {
  position: relative;
  background-color: #d99fff;
  padding-left: 1.4rem;
  cursor: help;
  margin-right: 0.4rem;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 60%;
    background-image: url('../../assets/img/svg/ai-stars.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.checkboxWithTooltip {
  display: flex;
}

.check-icon {
  @include size(18px);
  margin-left: 0.4rem;

  path:first-of-type {
    fill: #5AB963;
  }
  path:nth-of-type(2n){
    fill: #ffffff;
  }
}
</style>
